<template>
  <div class="menu-manager-container">
    <div class="button-block">
      <div>
        <el-button
          size="mini"
          type="primary"
          @click="dialogVisible=true">
          {{ $t('upload') }}
        </el-button>
      </div>
      <div>
        <el-button
          type="text"
          size="mini"
          icon="el-icon-refresh"
          @click="getEnterpriseInfo">
        </el-button>
      </div>
    </div>
    <div class="table-block">
      <el-table
        v-no-border
        v-loading="tableLoading"
        :data="attachmentList"
        style="width: 100%"
        row-key="id"
        default-expand-all
        height="100%"
        size="small"
        stripe
        :default-sort="{prop:'seq'}">
        <el-table-column
          type="index"
          :label="$t('serialNum')"
          :width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('attachmentName')">
        </el-table-column>
        <el-table-column
          prop="attachmentType"
          :label="$t('attachmentType')">
        </el-table-column>
        <el-table-column
          prop="uploader"
          :label="$t('uploader')">
        </el-table-column>
        <el-table-column
          prop="acceptor"
          :label="$t('acceptor')">
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          :label="$t('uploadTime')">
        </el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          :label="$t('operate')"
          width="100">
          <template slot-scope="scope">
            <!--删除-->
            <el-button
              style="font-size: 12px"
              :style="{color: $store.state.currentTheme.dangerColor}"
              type="text"
              @click="deleteAttachment(scope.row.id)">
              {{ $t('delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%">
      <base-title slot="title" :text="$t('upload')"></base-title>
      <div style="min-height: 260px;padding: 20px">
        <el-form
          v-if="dialogVisible"
          ref="form"
          :model="formData"
          label-width="140px">
          <el-form-item
            :label="$t('attachmentType')"
            prop="attachmentType"
            required>
            <el-radio-group v-model="formData.attachmentType">
              <el-radio
                v-for="item in attachmentTypeMap[enterpriseType]"
                :key="item"
                :label="item">
                {{ $t(item) }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('acceptor')">
            <el-select
              style="width: 400px"
              v-model="formData.acceptor"
              multiple
              :placeholder="$t('pleaseSelect')">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.enterpriseName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-upload
              action=""
              :auto-upload="false"
              :on-change="onAttachmentChange"
              :on-remove="onAttachmentDelete"
              accept=".jpg,.png,.pdf">
              <el-button size="small" type="primary">{{ $t('uploadAttachment') }}</el-button>
              <div slot="tip" class="el-upload__tip">{{ $t('uploadAttachmentTypeEnterprise') }}</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          @click="addAttachment">
          {{ $t('save') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import baseTableMixin from '@/mixins/base-table-mixin'

export default {
  name: 'attachmentManager',
  mixins: [baseTableMixin],
  data () {
    return {
      currentEnterprise: {},
      attachmentList: [],
      dialogVisible: false,
      formData: {
        attachmentType: '',
        acceptor: [],
        file: null
      },
      organizations: [],
      attachmentTypeMap: Object.freeze(
        {
          // 供油方
          CARGO_SELLER: ['CONTRACT', 'ORDER', 'INVOICE', 'COLLECTION_ORDER'],
          // 贸易商
          BUNKER_TRADER: [
            'CONTRACT', 'ORDER', 'FINANCING_APPLICATION_ORDER',
            'INVOICE', 'SALES_APPLICATION_ORDER', 'SALES_ORDER', 'COLLECTION_ORDER', 'PAYMENT_ORDER'
          ],
          // 资金提供商
          FINANCING_INSTITUTION: ['FINANCING_CONTRACT', 'COLLECTION_ORDER'],
          // 货轮公司
          SHIP_OWNER: ['ORDER', 'PAYMENT_ORDER'],
          // 驳船公司
          BUNKER_SUPPLIER: ['OIL_DISTRIBUTING_PLAN', 'LOADING_PLAN']
        }
      )
    }
  },

  computed: {
    enterpriseType () {
      return this.$store.state.currentCompany.enterpriseType
    }
  },

  created () {
    this.getEnterpriseInfo()
  },

  methods: {
    getOrganizationData () {
      /**
       * 获取机构数据
       */
      if (!this.organizations.length) {
        this.$request.post({
          url: this.$apis.getOrgListUrl
        }).then(data => {
          if (data?.code === 1000) {
            this.organizations = data.data
          }
        })
      }
    },
    getEnterpriseInfo () {
      /**
       * 获取企业的附件
       */
      this.$request.post({
        url: `${this.$apis.getOrgListUrl}?id=${this.$store.state.currentCompany.id}`
      }).then(data => {
        if (data?.code === 1000 && data.data.length) {
          this.attachmentList = data.data[0].attachment || []
        }
      })
    },
    onAttachmentChange (file) {
      /**
       * 监听文件变化
       */
      this.formData.file = file.raw
    },
    onAttachmentDelete () {
      /**
       * 监听文件移出
       */
      this.formData.file = null
    },
    addAttachment () {
      /**
       * 上传附件
       */
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$request.postFormData({
            url: this.$apis.cccc,
            data: this.formData
          }).then(data => {
            if (data?.code === 1000) {
              this.$message.success('uploadSuccess')
              this.getEnterpriseInfo()
              this.dialogVisible = false
            }
          })
        }
      })
    },
    deleteAttachment (id) {
      /**
       * 删除附件
       */
      this.$request.get({
        url: this.$apis.deleteCC,
        params: {
          id
        }
      }).then(data => {
        if (data?.code === 1000) {
          this.$message.success(this.$t('fileDeleteSuccess'))
          this.getEnterpriseInfo()
        }
      })
    }
  },
  watch: {
    dialogVisible (newValue) {
      if (newValue) {
        this.formData = this.$options.data().formData
        this.getOrganizationData()
      } else {
        this.$refs.form.clearValidate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-manager-container {
  height: 100%;
  width: 100%;

  .button-block {
    @include flex-center-row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
  }

  .table-block {
    width: 100%;
    height: calc(100% - 48px);
  }
}
</style>
