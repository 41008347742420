var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-manager-container" },
    [
      _c("div", { staticClass: "button-block" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = true
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("upload")) + " ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("el-button", {
              attrs: { type: "text", size: "mini", icon: "el-icon-refresh" },
              on: { click: _vm.getEnterpriseInfo },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c(
            "el-table",
            {
              directives: [
                { name: "no-border", rawName: "v-no-border" },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.attachmentList,
                "row-key": "id",
                "default-expand-all": "",
                height: "100%",
                size: "small",
                stripe: "",
                "default-sort": { prop: "seq" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: _vm.$t("serialNum"), width: 50 },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: _vm.$t("attachmentName") },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "attachmentType",
                  label: _vm.$t("attachmentType"),
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "uploader", label: _vm.$t("uploader") },
              }),
              _c("el-table-column", {
                attrs: { prop: "acceptor", label: _vm.$t("acceptor") },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  align: "center",
                  label: _vm.$t("uploadTime"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: _vm.$t("operate"),
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "font-size": "12px" },
                            style: {
                              color: _vm.$store.state.currentTheme.dangerColor,
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteAttachment(scope.row.id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("base-title", {
            attrs: { slot: "title", text: _vm.$t("upload") },
            slot: "title",
          }),
          _c(
            "div",
            { staticStyle: { "min-height": "260px", padding: "20px" } },
            [
              _vm.dialogVisible
                ? _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.formData, "label-width": "140px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("attachmentType"),
                            prop: "attachmentType",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formData.attachmentType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "attachmentType", $$v)
                                },
                                expression: "formData.attachmentType",
                              },
                            },
                            _vm._l(
                              _vm.attachmentTypeMap[_vm.enterpriseType],
                              function (item) {
                                return _c(
                                  "el-radio",
                                  { key: item, attrs: { label: item } },
                                  [_vm._v(" " + _vm._s(_vm.$t(item)) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("acceptor") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                multiple: "",
                                placeholder: _vm.$t("pleaseSelect"),
                              },
                              model: {
                                value: _vm.formData.acceptor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "acceptor", $$v)
                                },
                                expression: "formData.acceptor",
                              },
                            },
                            _vm._l(_vm.organizations, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.enterpriseName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "",
                                "auto-upload": false,
                                "on-change": _vm.onAttachmentChange,
                                "on-remove": _vm.onAttachmentDelete,
                                accept: ".jpg,.png,.pdf",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v(_vm._s(_vm.$t("uploadAttachment")))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("uploadAttachmentTypeEnterprise")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addAttachment },
                },
                [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }